import useTypedSelector from 'interfaces/useTypedSelector'

export const useRecentProducts = () => {
	const { recentlyViewed } = useTypedSelector(({ products }) => products)
	const { status, products } = recentlyViewed

	return {
		status,
		recentProducts: products.map(x => x.product),
	}
}
