import styled, { css } from 'styled-components'
import { styleParams, fluid, StyleParams } from 'helpers/styleHelpers/styleHelpers'

export const baseHeading = css`
	color: ${({ theme }) => theme.colors.textDark};
	font-weight: 800;
	line-height: 1;
	${styleParams};
`

export const HeroH1 = styled.h1<StyleParams>`
	${baseHeading};
	${fluid('font-size', '35px', '60px')};
	line-height: 1.1;
`

export const H1 = styled.h1<StyleParams>`
	${baseHeading};
	${fluid('font-size', '30px', '40px')};
	line-height: 1.25;
`

export const H2 = styled.h2<StyleParams>`
	${baseHeading};
	${fluid('font-size', '25px', '32px')};
	line-height: 1.2;
`

export const H3 = styled.h3<StyleParams>`
	${baseHeading};
	${fluid('font-size', '18px', '22px')};
	line-height: 1.36;
`

export const H4 = styled.h4<StyleParams>`
	${baseHeading};
	${fluid('font-size', '16px', '18px')};
	line-height: 1.33;
`

export const H5 = styled.h5<StyleParams>`
	${baseHeading};
	${fluid('font-size', '14px', '16px')};
	line-height: 1.375;
`

export const H6 = styled.h6<StyleParams>`
	${baseHeading};
	${fluid('font-size', '12px', '14px')};
	line-height: 1.28;
`
