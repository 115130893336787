import styled, { css } from 'styled-components'
import { styleParams, fluid, StyleParams } from 'helpers/styleHelpers/styleHelpers'

export const baseHeading = css`
	color: ${({ theme }) => theme.colors.textDark};
	font-weight: 800;
	line-height: 1;
	${styleParams};
`

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	${fluid('margin-top', '10rem', '5rem')};
	margin-bottom: 16rem;
`

export const InfoCont = styled.div`
	${fluid('margin-top', '3rem', '2rem')};
	color: 363a3f;
	text-align: center;
	width: 471px;
	${fluid('height', '14rem', '16rem')};
`

export const HeroNotFound = styled.h1<StyleParams>`
	${baseHeading};
	${fluid('font-size', '140px', '310px')};
	line-height: 1.1;
	color: ${({ theme }) => theme.colors.primary};
`
export const InfoTxt = styled.h3<StyleParams>`
	${baseHeading};
	${fluid('font-size', '16px', '22px')};
	line-height: 1.36;
`
