import React, { FC } from 'react'
import * as StyledHero from './frontpageHeroSlide.styles'
import { FrontpageHeroSlide as PrismicHeroSlide } from 'interfaces/prismic/frontpage'

interface Props {
	slide: PrismicHeroSlide
}

const FrontpageHeroSlide: FC<Props> = ({ slide }) => {
	return <StyledHero.HeroMain image={slide.slide_image}></StyledHero.HeroMain>
}

export default FrontpageHeroSlide
