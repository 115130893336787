export const getSliderSettings = (isClient: boolean, shouldAutoPlay: boolean) => ({
	dots: false,
	arrows: false,
	infinite: true,
	speed: 700,
	slidesToShow: 1,
	slidesToScroll: 1,
	adaptiveHeight: false,
	fade: false,
	draggable: false,
	autoplay: shouldAutoPlay,
	autoplaySpeed: 7000,
	responsive: isClient
		? [
				{
					breakpoint: 767,
					settings: {
						draggable: true,
					},
				},
		  ]
		: undefined,
})
