import React, { FC, useState, useEffect } from 'react'
import { FrontpageCategoryBox } from 'interfaces/prismic/frontpage'
import * as StyledCategoryBoxes from './categoryBoxes.styles'
import { H2 } from 'components/base/typography/headings'
// @ts-ignore
import { RichText } from 'prismic-reactjs'
import Link from 'next/link'
import { PageContainerLarge } from 'components/base/grid/grid'
import Slider from 'react-slick'
import { getSliderSettings } from './sliderSettings'

interface Props {
	boxes: FrontpageCategoryBox[]
}

export const CategoryBoxes: FC<Props> = ({ boxes }) => {
	if (!boxes || !boxes.length) return null

	const [isClient, setIsClient] = useState(false)
	useEffect(() => setIsClient(true), [])

	return (
		<PageContainerLarge>
			<StyledCategoryBoxes.BoxesContainer>
				<Slider {...getSliderSettings(isClient, boxes.length)} key={isClient ? 'client' : 'server'}>
					{boxes.map(box => (
						<StyledCategoryBoxes.CategoryBox length={boxes.length} key={`${box.box_title[0].text}${box.box_url_type}`}>
							<StyledCategoryBoxes.BoxImage image={box.box_image}>
								<Link
									href={`/${box.box_url_type}/[cslug]`}
									as={`/${box.box_url_type}/${RichText.asText(box.box_url_slug)}`}>
									<StyledCategoryBoxes.BoxTitle>
										<H2 color="white">{RichText.asText(box.box_title)}</H2>
									</StyledCategoryBoxes.BoxTitle>
								</Link>
							</StyledCategoryBoxes.BoxImage>
						</StyledCategoryBoxes.CategoryBox>
					))}
				</Slider>
			</StyledCategoryBoxes.BoxesContainer>
		</PageContainerLarge>
	)
}
