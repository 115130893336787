import useTypedSelector from 'interfaces/useTypedSelector'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { fetchProductsByCategorySlug } from 'reducers/productReducer'
import StorefrontProduct from '@local-types/products/storefrontProduct'
import { useActiveCompany } from 'hooks/useActiveCompany/useActiveCompany'
import {
	shouldPerformUnAuthenticatedProductFetch,
	shouldPerformAuthenticatedProductFetch,
} from 'helpers/productHelpers/productHelpers'

interface ProductSliderInfo {
	products: StorefrontProduct[]
	isLoading: boolean
}

const useProductsByCategorySlug = (slug: string, pageSize?: number): ProductSliderInfo => {
	const dispatch = useDispatch()
	const { authState } = useTypedSelector(({ auth }) => auth)
	const { activeRelation, state: userInfoState } = useActiveCompany()

	const { byCategorySlug } = useTypedSelector(({ products }) => products)
	const slider = byCategorySlug[slug]
	const companyId = byCategorySlug[slug]?.companyId
	const products = slider?.products
	const isLoading = slider?.status !== 'fulfilled'

	useEffect(() => {
		const dispatchUnAuthenticatedFetch = shouldPerformUnAuthenticatedProductFetch(
			authState,
			userInfoState,
			activeRelation
		)
		const dispatchAuthenticatedFetch = shouldPerformAuthenticatedProductFetch(
			userInfoState,
			companyId,
			activeRelation,
			1,
			1
		)

		if (dispatchUnAuthenticatedFetch) dispatch(fetchProductsByCategorySlug(slug, undefined, pageSize))
		else if (dispatchAuthenticatedFetch)
			dispatch(fetchProductsByCategorySlug(slug, activeRelation?.company.id, pageSize))
	}, [authState, companyId, activeRelation, userInfoState])
	return {
		products: products || [],
		isLoading,
	}
}

export default useProductsByCategorySlug
