import React, { FC, useRef, useState, useEffect } from 'react'
import * as StyledHero from './frontpageHero.styles'
import { PageContainerLarge } from '../../base/grid/grid'
import Slider from 'react-slick'
import Slide from './frontpageHeroSlide/frontpageHeroSlide'
import Frontpage from 'interfaces/prismic/frontpage'
// @ts-ignore
import { RichText } from 'prismic-reactjs'
import { getSliderSettings } from './sliderSettings'
import Button, { IconButton, IconButtonLabel } from 'components/base/buttons/buttons'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { setLoginMenuState } from 'reducers/headerReducer'
import Link from 'next/link'
import useTypedSelector from 'interfaces/useTypedSelector'
import { useActiveCompany } from 'hooks/useActiveCompany/useActiveCompany'
import { Strong } from 'components/base/typography/misc'
import { useSpring } from 'react-spring'
import useMeasure from 'hooks/useMeasure'
import Logo from 'components/logo'
import IconUserCircled from 'icons/userCircled.svg'
import IconCartAdd from 'icons/cartAdd.svg'
import IconCogwheel from 'icons/cogwheel.svg'
import IconHeart from 'icons/heart.svg'
import { Flex } from 'rebass'

interface Props {
	page: Frontpage
}

const FrontpageHero: FC<Props> = ({ page }) => {
	const dispatch = useDispatch()
	const { userInfo } = useTypedSelector(({ auth }) => auth)
	const { activeRelation } = useActiveCompany()
	const slides = page.data.hero_slides
	const sliderRef = useRef<Slider>(null)
	const [exitingIndex, setExitingIndex] = useState(-1)
	const [enteringIndex, setEnteringIndex] = useState(0)
	const [isClient, setIsClient] = useState(false)

	const { bind, bounds } = useMeasure()
	const props = useSpring({ height: bounds.height })

	useEffect(() => setIsClient(true), [])

	const handleLoginTriggerClick = () => {
		dispatch(setLoginMenuState(true))
	}

	const onBeforeChange = (exitIndex: number, enterIndex: number) => {
		setExitingIndex(exitIndex)
		setEnteringIndex(enterIndex)
	}

	const onPrev = () => {
		sliderRef.current && sliderRef.current.slickPrev()
	}

	const onNext = () => {
		sliderRef.current && sliderRef.current.slickNext()
	}

	const activeSlide = slides[enteringIndex]

	return (
		<StyledHero.Container>
			<StyledHero.Backdrop />
			<PageContainerLarge>
				<StyledHero.Wrapper>
					<StyledHero.SlideWrapper>
						<Slider
							{...getSliderSettings(isClient, slides.length > 1)}
							ref={sliderRef}
							key={isClient ? 'client' : 'server'}
							beforeChange={onBeforeChange}>
							{slides.map(slide => {
								return (
									<div key={`${slide.slide_title[0].text}${slide.slide_url_type}`}>
										<Slide slide={slide} />
									</div>
								)
							})}
						</Slider>
						<StyledHero.Box style={props}>
							<StyledHero.TitleWrapper {...bind}>
								{slides.map((slide, index) => {
									return (
										<StyledHero.HeroBoxTitle
											key={index}
											entering={enteringIndex === index}
											exiting={exitingIndex === index}>
											{RichText.asText(slide.slide_title)}
										</StyledHero.HeroBoxTitle>
									)
								})}
							</StyledHero.TitleWrapper>
							<StyledHero.HeroBoxButtonWrapper>
								<Link
									href={`/${activeSlide.slide_url_type}/[cslug]`}
									as={`/${activeSlide.slide_url_type}/${RichText.asText(activeSlide.slide_url_slug)}`}>
									<Button
										as="a"
										href={`/${activeSlide.slide_url_type}/${RichText.asText(activeSlide.slide_url_slug)}`}
										secondary
										clipText>
										{RichText.asText(activeSlide.slide_button_text)}
									</Button>
								</Link>
								<StyledHero.ControlsWrapper>
									<IconButton mr="1.3rem" onClick={onPrev}>
										<IconButtonLabel>Fyrri síða</IconButtonLabel>
										<FaChevronLeft />
									</IconButton>
									<IconButton onClick={onNext}>
										<IconButtonLabel>Næsta síða</IconButtonLabel>
										<FaChevronRight />
									</IconButton>
								</StyledHero.ControlsWrapper>
							</StyledHero.HeroBoxButtonWrapper>
						</StyledHero.Box>
					</StyledHero.SlideWrapper>
					<StyledHero.HeroLogin>
						<StyledHero.HeroLoginContent>
							{userInfo && activeRelation ? (
								<>
									<StyledHero.HeroTextWrapper>
										<StyledHero.HeroTextTitle>Halló, {userInfo.name}</StyledHero.HeroTextTitle>
										<StyledHero.HeroTextContent>
											Þú ert að versla sem
											<Strong ml="0.5rem">{activeRelation.company.name}</Strong>
										</StyledHero.HeroTextContent>
									</StyledHero.HeroTextWrapper>
									<StyledHero.HeroUserSettings>
										<StyledHero.HeroUserLink>
											<Link href="/minar-sidur/pantanir">
												<a href="/minar-sidur/pantanir">
													<IconCartAdd />
													Pantanir
												</a>
											</Link>
										</StyledHero.HeroUserLink>
										<StyledHero.HeroUserLink>
											<Link href="/minar-sidur/listar">
												<a href="/minar-sidur/listar">
													<IconHeart />
													Listar
												</a>
											</Link>
										</StyledHero.HeroUserLink>
										<StyledHero.HeroUserLink>
											<Link href="/minar-sidur/minir-notendur">
												<a href="/minar-sidur/minir-notendur">
													<IconUserCircled />
													Mínir notendur
												</a>
											</Link>
										</StyledHero.HeroUserLink>
										<StyledHero.HeroUserLink>
											<Link href="/minar-sidur/stillingar">
												<a href="/minar-sidur/stillingar">
													<IconCogwheel />
													Stillingar
												</a>
											</Link>
										</StyledHero.HeroUserLink>
									</StyledHero.HeroUserSettings>
								</>
							) : userInfo ? (
								<>
									<StyledHero.HeroTextWrapper>
										<StyledHero.HeroTextTitle>Halló, {userInfo.name}</StyledHero.HeroTextTitle>
										<StyledHero.HeroTextContent>
											Þú ert ekki tengd/ur við nein fyrirtæki sem stendur. Vinsamlegast hinkrið eftir að farið verði
											yfir umsókn þína.
										</StyledHero.HeroTextContent>
									</StyledHero.HeroTextWrapper>
								</>
							) : (
								<>
									<StyledHero.HeroTextWrapper>
										<StyledHero.HeroTextTitle>{RichText.asText(page.data.hero_login_title)}</StyledHero.HeroTextTitle>
										<StyledHero.HeroTextContent>
											{RichText.asText(page.data.hero_login_text)}
										</StyledHero.HeroTextContent>
									</StyledHero.HeroTextWrapper>
									<StyledHero.LoginButtonsWrapper>
										<Button mt="1rem" ternary onClick={handleLoginTriggerClick} mr="1rem">
											Innskráning
										</Button>
										<Link href="/nyskraning">
											<Button mt="1rem" as="a" href="/" primary>
												Ertu ný/r á Matarstræti?
											</Button>
										</Link>
									</StyledHero.LoginButtonsWrapper>
								</>
							)}
						</StyledHero.HeroLoginContent>
					</StyledHero.HeroLogin>
				</StyledHero.Wrapper>
			</PageContainerLarge>
		</StyledHero.Container>
	)
}

export default FrontpageHero
