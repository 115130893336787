export const getSliderSettings = (isClient: boolean, len: number) => ({
	dots: false,
	arrows: false,
	infinite: false,
	speed: 400,
	slidesToShow: len > 4 ? 4 : len,
	slidesToScroll: 1,
	adaptiveHeight: false,
	fade: false,
	draggable: false,
	autoplay: false,
	responsive: isClient
		? [
				{
					breakpoint: 1024,
					settings: {
						draggable: true,
						slidesToShow: 1,
						centerMode: true,
					},
				},
		  ]
		: undefined,
})
