import styled, { css, keyframes } from 'styled-components'
import { HeroH1, H2 } from '../../base/typography/headings'
import { media } from 'constants/media/media'
import CroppedPrismicImage from 'components/base/images'
import { fluid } from 'helpers/styleHelpers/styleHelpers'
import { animated } from 'react-spring'

export const enter = keyframes`
	0% { transform: translate3d(-3rem, 0, 0); opacity: 0}
	100% { transform: translate3d(0, 0, 0); opacity: 1 }
`

export const exit = keyframes`
	0% { opacity: 1}
	100% {opacity: 0}
`

export const Container = styled.div`
	position: relative;
`

export const Wrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: stretch;
	padding-bottom: 4rem;
	position: relative;

	${media.tablet`
		padding-bottom: 0;
	`}
	${media.mobileOnly`
		flex-wrap: wrap;
	`}
`

export const SlideWrapper = styled.div`
	flex: 1 0 65%;
	max-width: 58%;
	position: relative;

	${media.desktopSmall`
		max-width: 64%;
	`}
	.slick-slider,
	.slick-list,
	.slick-track {
		height: 100%;
	}
	.slick-track [aria-hidden='true'] {
		pointer-events: none;
	}
	.slick-track {
		display: flex !important;
		${media.mobileOnly`
		height: 500px;
	`}
	}
	.slick-slide {
		height: auto;
		overflow: hidden;
		> div {
			position: relative;
			height: 100%;
			> div {
				height: 100%;
			}
		}
	}
	${media.mobileOnly`
		max-width: 100%;
	`}
`

export const HeroLogin = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	height: 65vh;
	max-height: 65rem;
	flex: 1 1 100%;
	margin-left: 2rem;

	${media.mobileOnly`
		display: none;
	`}
`

export const HeroLoginLogo = styled.div`
	padding-bottom: 8rem;
	max-width: 50rem;
	display: flex;
	justify-content: center;
	align-items: center;
`

export const Backdrop = styled.div`
	position: absolute;
	top: 3rem;
	bottom: -6rem;
	left: 60%;
	right: 0;
	z-index: -1;
	background-image: url('/images/frontpage-backdrop.svg');

	${media.retina`
		left: 55%;
		bottom: -22.5rem;
	`}

	${media.mobileOnly`
		display: none;
	`}
`

export const HeroLoginImage = styled(CroppedPrismicImage)`
	padding-top: 60%;
	min-height: 22rem;
	background-size: cover;
	background-position: 50% 50%;
`

export const HeroLoginContent = styled.div`
	padding: 3.5rem 4rem;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;

	${media.desktopLarge`
		padding: 4.7rem 5.2rem;
	`}
`

export const HeroUserSettings = styled.div`
	padding-top: 2rem;
	max-width: 50rem;
	display: flex;
	flex-wrap: wrap;
`

export const HeroUserLink = styled.div`
	margin-bottom: 2rem;
	width: 50%;

	a {
		display: flex;
		align-items: center;
		font-size: 1.6rem;
		font-weight: 700;
		color: ${({ theme }) => theme.colors.blueDark};
		transition: color 250ms;

		svg {
			margin-right: 1rem;
			height: auto;
			width: 2.8rem;

			path {
				transition: fill 250ms;
				fill: ${({ theme }) => theme.colors.primary};
			}
		}

		&:hover {
			color: ${({ theme }) => theme.colors.primary};

			svg {
				path {
					fill: ${({ theme }) => theme.colors.primaryDark};
				}
			}
		}
	}
`

export const HeroTextWrapper = styled.div`
	max-width: 50rem;
	margin-bottom: 4rem;
`

export const HeroTextTitle = styled(H2)`
	margin-bottom: 1.5rem;
`

export const HeroTextContent = styled.div`
	font-weight: 400;
`

export const LoginButtonsWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
`

export const Box = styled(animated.div)`
	position: absolute;
	left: -1;
	bottom: 3rem;
	background-color: #fff;
	transform: translateZ(0);

	overflow: hidden;
	${media.desktopLarge`
	min-width: 50%;
	width: 50%;
	`}
	${media.desktopSmallOnly`
		min-width: 70%;
		width: 70%;
	`}

	${media.tabletAndMobileOnly`
		min-width: calc(100% - 3rem);
		width: calc(100% - 3rem);
	`}
`
export const TitleWrapper = styled(animated.div)`
	position: relative;
`
interface HeroBoxTitleProps {
	entering: boolean
	exiting: boolean
}
export const HeroBoxTitle = styled(HeroH1)<HeroBoxTitleProps>`
	${fluid('font-size', '30px', '50px')};
	opacity: 0;
	display: none;
	animation: ${enter} 200ms 200ms forwards;
	margin: 3.5rem 3.5rem 11rem 3.5rem;

	${media.desktopSmall`
		margin-bottom: 12rem;
	`}

	${({ entering }) =>
		entering &&
		css`
			display: inline-block;
			animation: ${enter} 300ms 300ms forwards;
		`};
	${({ exiting }) =>
		exiting &&
		css`
			${media.tablet`
				animation: ${exit} 300ms forwards;
			`}
		`};
`

const SlideIn = keyframes`
	0% {
		opacity: 0;
		left: -100%
	}
	100% {
		opacity: 1;
		left: 0;
	}
`

export const HeroBoxButtonWrapper = styled.div`
	animation: ${SlideIn} 400ms ease-in;
	animation-delay: 500ms;
	animation-fill-mode: forwards;
	position: absolute;
	opacity: 1;
	bottom: 0;
	width: calc(100% - 7.5rem);
	display: flex;
	justify-content: space-between;
	margin: 0 3.5rem 3.5rem 3.5rem;
	opacity: 0;
	left: -100%;
`

export const ControlsWrapper = styled.div`
	display: flex;
	align-items: center;
	margin-left: 3rem;

	${media.mobileOnly`
		margin-left: 2.5rem;
	`}
`
