import React, { FC } from 'react'
import styled, { css } from 'styled-components'
import { PrismicImage } from 'interfaces/prismic/fields/fields'
import { media } from 'constants/media/media'

interface StyleProps {
	mobile: string
	tablet: string
	desktop: string
	desktopLarge: string
	name: string
}

const StyledImage = styled.div<StyleProps>`
	background-image: url(${({ mobile }) => mobile});
	background-size: cover;

	${({ tablet, desktop, desktopLarge }) => css`
		${media.tablet`
			background-image: url(${tablet});
		`}
		${media.desktopSmall`
			background-image: url(${desktop});
		`}
		${media.desktopHuge`
			background-image: url(${desktopLarge});
		`}
	`}
`

export interface CroppedPrismicImageProps {
	image: PrismicImage
}

const CroppedPrismicImage: FC<CroppedPrismicImageProps> = ({ image, ...props }) => (
	<StyledImage
		mobile={image.mobile.url}
		tablet={image.tablet.url}
		desktop={image.desktop.url}
		desktopLarge={image.desktop_large.url}
		{...props}
		name="Cropped-Prismic-Image"
	/>
)

export default CroppedPrismicImage
