import React, { FC } from 'react'
import * as StyledHero from './standardHero.styles'
import { PrismicImage, PrismicTextField } from 'interfaces/prismic/fields/fields'
// @ts-ignore
import { RichText } from 'prismic-reactjs'

interface Props {
	title?: string | PrismicTextField[]
	image: string | PrismicImage
	short?: boolean
	desktopOnly?: boolean
	imageOnMobile?: boolean
	showHeroTitle?: boolean
}

const StandardHero: FC<Props> = ({ title, image, short, desktopOnly, imageOnMobile, showHeroTitle = true }) => {
	const titleValue: string = typeof title === 'string' || !title ? title : RichText.asText(title)

	if (typeof image === 'string') {
		return (
			<StyledHero.Wrapper desktopOnly={desktopOnly} short={short}>
				<StyledHero.StandardImage imageUrl={image} imageOnMobile={imageOnMobile} />
				{showHeroTitle && titleValue && <StyledHero.HeroTitle>{titleValue}</StyledHero.HeroTitle>}
			</StyledHero.Wrapper>
		)
	}
	return (
		<StyledHero.Wrapper desktopOnly={desktopOnly} short={short}>
			<StyledHero.PrismicImage imageOnMobile={imageOnMobile} image={image} />
			{showHeroTitle && titleValue && <StyledHero.HeroTitle>{titleValue}</StyledHero.HeroTitle>}
		</StyledHero.Wrapper>
	)
}

export default StandardHero
