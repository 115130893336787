import styled from 'styled-components'
import CroppedPrismicImage from 'components/base/images'
import { media } from 'constants/media/media'

export const BoxesContainer = styled.div`
	display: flex;
	padding: 2.5rem 0 2.5rem 1.5rem;
	width: 100%;

	${media.desktopSmall`
        padding: 2.5rem 1.5rem;
    `}

	.slick-slider {
		width: 100%;
	}

	.slick-track {
		display: flex;
	}

	.slick-slide {
		margin-right: 2rem;

		&:first-child {
			margin-left: 1rem;
		}

		${media.desktopSmall`
            margin: 0 1rem;
        `}
	}

	.custom-dots {
		display: flex !important;
	}
`

interface BoxProps {
	length: number
}

export const CategoryBox = styled.div<BoxProps>`
	height: 20rem;
	width: ${({ length }) => `${100 / length}%`};
	min-width: 26rem;

	${media.desktopSmallOnly`
        height: 18rem;
        min-width: 22rem;
    `}

	${media.tabletAndMobileOnly`
        margin-left: -5rem;
        min-width: 16rem;
    `}
`

export const BoxImage = styled(CroppedPrismicImage)`
	height: 100%;
	width: 100%;
	background-size: cover;
	background-position: 50% 50%;
`

export const BoxTitle = styled.div`
	width: 100%;
	height: 100%;
	background: linear-gradient(180deg, rgba(0, 0, 0, 0.03) 0%, rgba(0, 0, 0, 0.3) 100%);
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	text-align: center;
	transition: background-color 250ms ease;

	&:hover {
		background-color: rgba(0, 0, 0, 0.3);
	}
`
