import React from 'react'
import { NextPage } from 'next'
import FrontpageHero from '../components/hero/frontpageHero'
import ProductCarousel from '../components/products/productCarousel'
import StandardHero from 'components/hero/standardHero'
import { Client } from 'prismicConfiguration'
import Frontpage from 'interfaces/prismic/frontpage'
import NotFound from 'components/notFound'
import useRecentProducts from 'hooks/useRecentProducts'
import CategoryBoxes from 'components/categoryBoxes'
import useProductsByCategorySlug from 'hooks/useProductsByCategorySlug'
import useTypedSelector from 'interfaces/useTypedSelector'
interface Props {
	page: Frontpage | null
}

const Home: NextPage<Props> = ({ page }) => {
	const sliderPageSize = 50
	const discountSlider = useProductsByCategorySlug('discount-product-slider', sliderPageSize)
	const productSlider = useProductsByCategorySlug('popular-product-slider', sliderPageSize)
	const { recentProducts, status } = useRecentProducts()
	const { productLists, status: listStatus } = useTypedSelector(({ productLists }) => productLists)

	if (!page) {
		return <NotFound />
	}

	return (
		<>
			<FrontpageHero page={page} />
			<CategoryBoxes boxes={page.data.category_boxes} />
			{Array.isArray(productLists) && productLists.length > 0 && (
				<ProductCarousel
					productList={productLists}
					backgroundImage="/images/wishListBackground.png"
					loading={listStatus === 'pending'}
					title="Þínir listar"
					url="/minar-sidur/listar"
				/>
			)}
			<ProductCarousel
				products={productSlider.products}
				loading={productSlider.isLoading}
				title="Vinsælar vörur"
				url="flokkur/popular-product-slider"
			/>
			{recentProducts.length >= 5 && (
				<ProductCarousel
					products={recentProducts}
					loading={status === 'pending'}
					title="Vörur sem þú skoðaðir síðast"
				/>
			)}
			<StandardHero imageOnMobile image={page.data.bottom_banner} />
			<ProductCarousel
				products={discountSlider.products}
				loading={discountSlider.isLoading}
				title="Vörur á tilboði"
				url="/flokkur/tilbod"
			/>
		</>
	)
}

Home.getInitialProps = async () => {
	try {
		const page = await Client().getSingle('frontpage', {})
		return { page }
	} catch (error) {
		return { page: null }
	}
}

export default Home
