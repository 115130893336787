import React from 'react'
import * as StyledNF from './notFound.styles'
import { PageContainer } from 'components/base/grid/grid'
import { Button } from 'components/base/buttons/buttons'
import Link from 'next/link'

export const NotFound = () => {
	return (
		<PageContainer>
			<StyledNF.Container>
				<StyledNF.HeroNotFound>404</StyledNF.HeroNotFound>
				<StyledNF.InfoCont>
					<StyledNF.InfoTxt>Úpps, Síðan sem þú leitaðir að er ekki til.</StyledNF.InfoTxt>
					<StyledNF.InfoTxt>Reyndu að leita að því sem þú þarft eða</StyledNF.InfoTxt>
					<StyledNF.InfoTxt>farðu aftur á forsíðu.</StyledNF.InfoTxt>
				</StyledNF.InfoCont>
				<Button as="a" href="/" secondary>
					Fara aftur á forsíðu
				</Button>
			</StyledNF.Container>
		</PageContainer>
	)
}
