import styled, { css } from 'styled-components'
import { H1 } from '../../base/typography/headings'
import CroppedPrismicImage from 'components/base/images'
import { media } from 'constants/media/media'

interface WrapperProps {
	short?: boolean
	desktopOnly?: boolean
}

export const Wrapper = styled.div<WrapperProps>`
	position: relative;
	padding: 10% 5%;
	min-height: 30rem;

	${media.mobileOnly`
		h1 {
			margin-top: 3rem;
			padding: 1rem 0;
		}
	`}

	${({ short }) => css`
		${media.desktopSmall`
			padding: 0;
			padding-bottom: ${short ? '15%' : '26%'};
		`}
		${media.mobileOnly`
			h1 {
				padding: 1rem 1rem;
			}
		`}
	`}

	${({ desktopOnly }) =>
		desktopOnly &&
		css`
			${media.tabletAndMobileOnly`
			display: none;
		`}
		`}
`

interface ImageProps {
	imageUrl?: string
	imageOnMobile?: boolean
}

export const StandardImage = styled.div<ImageProps>`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: 50% 50%;
	background-image: url(${({ imageUrl }) => imageUrl});

	${({ imageOnMobile }) =>
		!imageOnMobile &&
		css`
			${media.mobileOnly`
			display: none;
		`}
		`}
`

export const PrismicImage = styled(CroppedPrismicImage)<ImageProps>`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: 50% 50%;

	${({ imageOnMobile }) =>
		!imageOnMobile &&
		css`
			${media.mobileOnly`
			display: none;
		`}
		`}
`

export const HeroTitle = styled(H1)`
	min-width: 85%;
	max-width: 90%;
	margin: 0 auto;
	background-color: #fff;
	padding: 3.4rem 3.2rem;
	text-align: center;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	${media.desktopSmall`
		min-width: initial;
		text-align: left;
		margin: 0;
	`}
`
