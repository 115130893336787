import styled from 'styled-components'
import CroppedPrismicImage from 'components/base/images'

export const HeroMain = styled(CroppedPrismicImage)`
	position: relative;
	height: 100%;
	max-width: 100%;
	max-height: 100%;
	background-size: cover;
	background-position: 50% 50%;
`
